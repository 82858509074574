/* line 7, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
html {
  width: 100%;
  overflow-x: hidden; }

/* line 8, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
a {
  color: #FAAF1B; }

/* line 11, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
a:hover, a:focus {
  color: #da741b;
  text-decoration: underline; }

/* line 16, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
#main-nav {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-box-shadow: 0px 10px 5px 0px rgba(50, 50, 50, 0.56);
  -moz-box-shadow: 0px 10px 5px 0px rgba(50, 50, 50, 0.56);
  box-shadow: 0px 10px 5px 0px rgba(50, 50, 50, 0.56); }
  /* line 24, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #main-nav .expand {
    position: relative; }
    /* line 27, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #main-nav .expand ul {
      position: absolute;
      display: none;
      width: 280px;
      opacity: 0;
      transition: opacity 0.5s ease; }
    /* line 35, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #main-nav .expand:hover ul {
      display: block;
      opacity: 1;
      transition: opacity 0.5s ease; }

/* line 43, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
#header {
  padding-top: 50px; }
  /* line 46, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #header .top {
    position: relative;
    z-index: 5;
    -webkit-box-shadow: 0px 10px 5px 0px rgba(50, 50, 50, 0.56);
    -moz-box-shadow: 0px 10px 5px 0px rgba(50, 50, 50, 0.56);
    box-shadow: 0px 10px 5px 0px rgba(50, 50, 50, 0.56); }
    /* line 50, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #header .top .bann {
      position: absolute;
      z-index: 1;
      top: 0px;
      left: 0; }
    /* line 57, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #header .top .post-it {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 130%; }
    /* line 63, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #header .top .site-title {
      position: absolute;
      z-index: 100;
      left: 20%;
      top: 15px;
      color: #fff;
      font-size: 2.5em;
      font-family: "Kalam", cursive; }
  /* line 73, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #header .landing {
    position: relative;
    height: 230px;
    overflow: hidden; }
    /* line 77, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #header .landing img {
      position: absolute;
      top: 0;
      left: 0; }

/* line 85, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
#index #header .landing {
  position: relative;
  height: 425px;
  overflow: visible; }
  /* line 89, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #index #header .landing img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    max-width: 100%;
    height: auto; }

/* line 101, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
#main-nav {
  background: #6FB3D2; }
  /* line 104, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #main-nav .navbar-nav {
    width: 100%; }
    /* line 106, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #main-nav .navbar-nav li {
      width: 16.666666%; }
      /* line 108, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
      #main-nav .navbar-nav li a {
        display: block;
        text-align: center;
        text-transform: uppercase;
        color: #183a4b;
        font-weight: bold;
        letter-spacing: 2px;
        transition: all 0.5s ease; }
        /* line 117, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
        #main-nav .navbar-nav li a:hover, #main-nav .navbar-nav li a.active {
          background: #376277;
          color: #fff;
          text-decoration: none;
          letter-spacing: 0px;
          transition: all 0.5s ease; }
      /* line 127, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
      #main-nav .navbar-nav li:hover > a {
        background: #376277;
        color: #fff;
        text-decoration: none;
        letter-spacing: 0px;
        transition: all 0.5s ease; }
    /* line 137, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #main-nav .navbar-nav > li + li {
      border-left: 1px dotted #fff; }
  /* line 140, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #main-nav .expand {
    transition: background-color 0.5s ease; }
    /* line 142, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #main-nav .expand:hover {
      background: #376277;
      color: #fff;
      text-decoration: none;
      letter-spacing: 0px;
      transition: all 0.5s ease; }
    /* line 150, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #main-nav .expand ul {
      background: #6FB3D2;
      border-top: 2px solid #fff; }
      /* line 154, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
      #main-nav .expand ul li {
        width: 100%; }
        /* line 157, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
        #main-nav .expand ul li + li {
          border-top: 1px dotted #fff; }
      /* line 162, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
      #main-nav .expand ul a {
        display: block;
        text-align: left;
        font-size: 1em;
        padding: 5px 10px;
        text-transform: none; }

/* line 177, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.top {
  background: #3A88AE;
  padding: 0 0; }
  /* line 182, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .top > div > p {
    display: inline-block;
    margin-bottom: 0;
    color: #fff;
    font-size: 1.3em;
    line-height: 70px;
    font-family: "Montserrat", sans-serif; }
    /* line 190, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .top > div > p + p {
      margin-left: 25px; }
    /* line 194, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .top > div > p > i, .top > div > p > a i {
      padding: 10px;
      width: 40px;
      text-align: center;
      font-size: 20px;
      line-height: 20px;
      background: #fff;
      color: #3A88AE;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%; }
    /* line 204, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .top > div > p > a {
      color: #fff; }

/* line 213, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.post-it address {
  position: relative;
  padding: 10px 25px 10px 25px;
  font-weight: bold;
  transform: rotate(-5deg); }
  /* line 218, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .post-it address strong {
    font-family: "Montserrat", sans-serif;
    color: #3A88AE; }
  /* line 223, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .post-it address:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -10px;
    left: 0;
    width: 120%;
    height: 120%;
    background: #fff; }

/* line 237, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.acces-rapide {
  visibility: hidden;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
  /* line 258, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide > div {
    color: #183a4b; }
    /* line 262, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .acces-rapide > div .title {
      position: relative;
      font-size: 1.3em;
      padding: 0 0 0 45px; }
  /* line 269, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide > div:first-child {
    background: #ffb266; }
  /* line 275, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide > div:last-child {
    background: #6FB3D2; }
  /* line 279, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide .offres,
  .acces-rapide .documents {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: 50px; }

/* line 302, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.acces-rapide-lat {
  visibility: hidden;
  position: relative; }
  /* line 307, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide-lat #mov {
    position: absolute;
    width: 100%;
    top: 0; }
    /* line 312, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .acces-rapide-lat #mov.fixed {
      position: fixed;
      z-index: 100;
      min-width: 250px;
      max-width: 390px;
      top: 80px; }
  /* line 321, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide-lat > div > * {
    color: #183a4b;
    min-height: 100px; }
    /* line 325, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .acces-rapide-lat > div > * .title {
      position: relative;
      font-size: 1.3em;
      padding: 10px 0 10px 45px;
      margin: 0; }
  /* line 333, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide-lat > div > *:first-child {
    background: #9AD051; }
  /* line 336, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide-lat > div > *:nth-child(2) {
    background: #71CCF9; }
  /* line 339, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide-lat > div > *:last-child {
    background: #FAAF1B; }

/* line 343, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.reservation .title:after,
.documents .title:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  height: 1px;
  width: 200px;
  margin-left: -100px;
  border-bottom: 1px dotted #183a4b; }

/* line 355, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.reservation .title:before,
.documents .title:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 40px;
  width: 40px;
  margin-top: -20px;
  background-image: url(../img/sprite-ico.png);
  background-repeat: no-repeat;
  background-position: left top; }

/* line 370, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.reservation .title:before {
  background-position: left bottom; }

/* line 373, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.reservation .title a {
  color: #183a4b;
  letter-spacing: 0px;
  transition: all 0.5s ease; }
  /* line 378, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .reservation .title a:hover {
    text-decoration: none;
    letter-spacing: -1px;
    transition: all 0.5s ease; }

/* line 386, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.reservation ul {
  margin: 20px 10px 0px 40px; }
  /* line 388, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .reservation ul > li + li {
    border-top: 1px dotted #183a4b; }
  /* line 391, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .reservation ul > li > a {
    display: block;
    padding: 5px 0 5px 0;
    color: #183a4b;
    letter-spacing: 0px;
    transition: all 0.5s ease; }
    /* line 398, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .reservation ul > li > a:hover {
      text-decoration: none;
      letter-spacing: -1px;
      transition: all 0.5s ease; }

/* line 407, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.offres a.title {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0; }

/* line 415, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.acces-rapide .documents {
  position: relative;
  height: 100%; }
  /* line 418, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide .documents h1.title {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0; }
  /* line 424, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide .documents ul {
    display: none;
    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;
    right: 0;
    background: #6FB3D2;
    padding: 0px 10px 0px 10px; }
    /* line 441, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .acces-rapide .documents ul > li + li {
      border-top: 1px dotted #183a4b; }
    /* line 444, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .acces-rapide .documents ul > li > a {
      display: block;
      padding: 5px 0 5px 0;
      color: #183a4b;
      letter-spacing: 0px;
      transition: all 0.5s ease; }
      /* line 451, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
      .acces-rapide .documents ul > li > a:hover {
        text-decoration: none;
        letter-spacing: -1px;
        transition: all 0.5s ease; }
  /* line 459, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide .documents:hover ul,
  .acces-rapide .documents.is-hover ul {
    display: block; }
  /* line 463, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide .documents button {
    width: 100%;
    border: none;
    background: none; }

/* line 472, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.acces-rapide-lat .documents ul {
  margin: 20px 10px 0px 40px;
  -webkit-column-count: 1;
  /* Chrome, Safari, Opera */
  -moz-column-count: 1;
  /* Firefox */
  column-count: 1;
  -webkit-column-width: 100%;
  /* Chrome, Safari, Opera */
  column-width: 100%; }
  /* line 480, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide-lat .documents ul > li:nth-child(2n+2) {
    border-top: 1px dotted #183a4b; }
  /* line 483, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide-lat .documents ul > li + li {
    border-top: 1px dotted #183a4b; }
  /* line 486, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide-lat .documents ul > li > a {
    display: block;
    padding: 5px 0 5px 0;
    color: #183a4b; }

/* line 494, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.offres a.title {
  font-size: 1.5em;
  padding: 8px 0;
  margin-top: 2px;
  width: 30%;
  display: inline-block;
  text-indent: -99999px;
  overflow: hidden; }
  /* line 503, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .offres a.title a {
    display: block;
    padding-left: 100px;
    letter-spacing: 0px;
    transition: all 0.5s ease; }
    /* line 510, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .offres a.title a:hover {
      text-decoration: none;
      letter-spacing: -1px;
      transition: all 0.5s ease; }
  /* line 517, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .offres a.title:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    height: 100px;
    width: 100px;
    margin-top: -50px;
    background-image: url(../img/promo.png);
    background-repeat: no-repeat;
    transform: scale(0.62); }

/* line 533, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.offres a {
  color: #183a4b;
  display: inline-block; }
  /* line 536, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .offres a + a {
    width: 70%;
    font-size: 1.3em;
    text-align: center; }

/* line 543, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.acces-rapide-lat .offres .title {
  margin-top: 0;
  padding-top: 30px; }

/* line 545, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.main-content {
  margin-top: 30px; }
  /* line 548, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .main-content h1 {
    font-size: 1.5em; }

/* line 553, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
#map {
  height: 400px; }

/* line 558, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.bandeau-horaires h1 {
  font-size: 1.4em;
  color: #fff;
  margin-top: 0; }

/* line 564, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.horaires1 {
  border: 1px solid #fff; }
  /* line 567, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .horaires1 h2 {
    margin: 0;
    padding: 5px 0;
    text-align: center;
    background: #fff; }
  /* line 573, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .horaires1 ul > li {
    display: inline-block;
    width: 49%;
    vertical-align: middle;
    padding: 5px 10px;
    margin: 5px 0; }
    /* line 580, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .horaires1 ul > li em {
      font-size: 0.8em; }
  /* line 585, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .horaires1 ul > li + li {
    border-left: 1px dotted #fff; }
  /* line 588, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .horaires1 .horaire {
    border: 2px solid #71CCF9;
    margin-bottom: 1.5em; }
  /* line 593, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .horaires1 .horaires2 > li {
    display: block;
    width: 100%; }

/* line 600, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.horaires2 > li {
  margin: 10px 0 10px 25px;
  padding: 0 10px;
  border-left: 5px solid #fff;
  font-size: 1.2em;
  width: 100%; }

/* line 607, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.horaires2 > li:first-child {
  margin-top: 0; }

/* line 612, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
#page .horaires1 {
  border: 1px solid #71CCF9; }
  /* line 615, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #page .horaires1 h2 {
    background: #71CCF9; }
  /* line 620, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #page .horaires1 ul > li + li {
    border-left: 1px dotted #71CCF9; }

/* line 625, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
#page .horaires2 > li {
  border-left: 5px solid #71CCF9; }

/* line 631, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.loisir-vignette {
  margin-bottom: 15px; }
  /* line 632, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .loisir-vignette a {
    display: block;
    position: relative;
    height: 50px;
    overflow: hidden; }
    /* line 638, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .loisir-vignette a img {
      position: absolute;
      bottom: 0; }

/* line 645, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.loisir {
  margin: 15px 0; }
  /* line 648, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .loisir li + li {
    padding-top: 5px;
    border-top: 1px dotted #183a4b; }

/* line 655, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.home-hebergements h2 {
  font-size: 1.3em; }

/* line 659, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.home-hebergements figcaption {
  padding-top: 10px; }

/* line 665, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.home-services {
  margin-top: 30px; }
  /* line 667, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .home-services a {
    color: #183a4b; }
  /* line 670, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .home-services p {
    position: relative;
    text-align: center;
    padding-top: 120px; }
    /* line 675, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p:before {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      width: 100px;
      height: 100px;
      margin-left: -50px;
      background-image: url(../img/sprite-services.png);
      background-repeat: no-repeat;
      background-position: left top; }
    /* line 690, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.baignade:before {
      background-position: left -750px; }
    /* line 693, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.boulangerie:before {
      background-position: left -150px; }
    /* line 696, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.wifi:before {
      background-position: left -300px; }
    /* line 699, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.peche:before {
      background-position: left -450px; }
    /* line 702, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.enfants:before {
      background-position: left top; }
    /* line 705, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.animaux:before {
      background-position: left -600px; }
    /* line 708, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.poney:before {
      background-position: left -900px; }
    /* line 711, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.arc:before {
      background-position: left -1050px; }
    /* line 714, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.rando:before {
      background-position: left -1200px; }
    /* line 717, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.bbc:before {
      background-position: left -1350px; }
    /* line 720, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.petanque:before {
      background-position: left -1500px; }
    /* line 723, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.cb:before {
      background-position: left -1650px; }
    /* line 726, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.ancv:before {
      background-position: left -1800px; }
    /* line 729, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.charentem:before {
      background-position: left -1950px; }
    /* line 732, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services p.plein-air:before {
      background-position: left -2100px; }
  /* line 736, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .home-services .avis p {
    padding-top: 0px; }
    /* line 738, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .home-services .avis p:before {
      display: none; }

/* line 743, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.flexbox-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  justify-content: space-around; }

/* line 759, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.col-icon {
  width: 20%; }

/* line 764, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.footer-icon .col-icon {
  -webkit-flex: 1;
  flex: 1; }

/* line 770, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.home-services .flexbox-container.small-ico p:before {
  transform: scale(0.8); }

/* line 776, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.mobil-home h2 {
  font-size: 1.4em;
  color: #3A88AE; }

/* line 782, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.mobil-list li {
  font-size: 0.8em; }

/* line 787, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.mobil-galerie {
  margin: 10px 0 0 0; }
  /* line 789, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .mobil-galerie li {
    height: 40px;
    padding: 0 5px;
    margin-bottom: 5px;
    overflow: hidden; }
    /* line 795, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .mobil-galerie li a {
      display: block;
      vertical-align: middle; }

/* line 804, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.page-camping > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center; }

/* line 822, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
footer {
  margin-top: 20px;
  padding: 20px 0;
  color: #fff;
  background: #3A88AE; }
  /* line 828, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  footer address {
    font-weight: bold; }
  /* line 830, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  footer strong {
    font-size: 1.3em; }
  /* line 835, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  footer ul li + li {
    border-top: 1px dotted #fff; }
  /* line 839, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  footer ul li > a {
    display: block;
    padding: 5px;
    color: #fff;
    letter-spacing: 0px;
    transition: all 0.5s ease; }
    /* line 846, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    footer ul li > a:hover {
      text-decoration: none;
      letter-spacing: 2px;
      transition: all 0.5s ease; }
  /* line 855, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  footer figure img {
    margin: 0 auto; }
  /* line 858, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  footer figure figcaption {
    text-align: center;
    color: #fff; }
  /* line 864, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  footer a {
    color: #fff; }
  /* line 866, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  footer .bottom-list {
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px dotted #fff; }
    /* line 871, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    footer .bottom-list li {
      border: none;
      font-size: 0.8em; }

/* line 875, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.zvr-widget .section {
  margin: 0;
  padding: 0; }

/* line 879, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.zvr-widget .label {
  color: #4a5b65; }

/* line 884, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.main-title, .h1 {
  font-size: 2em;
  color: #3A88AE; }

/* line 888, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
h2 {
  font-size: 1.5em; }

/* line 892, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.section {
  margin: 30px 0;
  padding: 30px 0; }

/* line 897, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.bg-color-type-4 {
  background: #71CCF9; }

/* line 901, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
i.big {
  font-size: 2em; }

/* line 902, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.big {
  font-size: 1.3em; }

/* line 904, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.image-fluid {
  margin: 0 10px 10px -15px; }

/* line 908, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.btn-resa {
  padding: 5px 10px;
  background: #FAAF1B;
  color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  transition: background 0.5s ease; }
  /* line 915, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .btn-resa i {
    transition: transform 0.5s ease; }
  /* line 919, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .btn-resa:hover {
    background: #c58505;
    color: #fff;
    transition: background 0.5s ease; }
    /* line 924, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .btn-resa:hover i {
      transform: rotate(360deg);
      transition: transform 0.5s ease; }

/* line 932, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
#destinationForm {
  margin-bottom: 10px; }

/* line 936, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.plan a {
  display: block; }

@media (max-width: 1650px) {
  /* line 944, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .top {
    height: 70px; }
    /* line 946, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .top .container {
      position: absolute;
      z-index: 2;
      right: 5px; } }

@media (max-width: 1120px) {
  /* line 957, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #header .top .bann {
    left: -100px; }
  /* line 960, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #header .top .post-it {
    top: 150%; }
  /* line 965, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #index #header .landing {
    position: relative;
    height: 330px;
    overflow: hidden; }
    /* line 969, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #index #header .landing img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      max-width: none;
      min-width: 1120px;
      height: auto; } }

@media (max-width: 1490px) {
  /* line 983, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #index #header .landing {
    position: relative;
    height: 280px;
    overflow: visible; }
    /* line 987, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #index #header .landing img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      max-width: none;
      min-width: 1120px;
      height: auto; } }

@media (max-width: 991px) {
  /* line 1000, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .navbar-nav > li > a {
    font-size: 12px; }
  /* line 1003, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide-lat .documents ul,
  .reservation ul {
    margin-left: 20px; }
  /* line 1007, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .offres .title {
    font-size: 1.3em; }
    /* line 1010, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .offres .title a {
      padding-left: 55px; }
  /* line 1015, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide > div .title {
    font-size: 1.1em; }
  /* line 1018, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .documents ul {
    margin: 20px 0 0 0; }
  /* line 1021, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide > div {
    height: 165px; }
  /* line 1026, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide .documents ul {
    margin: 20px 0 0 0;
    -webkit-column-count: 1;
    /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    /* Firefox */
    column-count: 1;
    -webkit-column-width: 100%;
    /* Chrome, Safari, Opera */
    column-width: 100%; }
    /* line 1034, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .acces-rapide .documents ul li + li {
      border-top: 1px dotted #fff; }
  /* line 1041, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .home-services > article:nth-child(5) {
    clear: left; } }

@media (max-width: 769px) {
  /* line 1045, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #google-t {
    bottom: 30px; }
  /* line 1050, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .home-services > article:nth-child(5) {
    clear: none; }
  /* line 1052, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #nav-mobile {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    background: #9AD051;
    -webkit-box-shadow: 0px 10px 5px 0px rgba(50, 50, 50, 0.56);
    -moz-box-shadow: 0px 10px 5px 0px rgba(50, 50, 50, 0.56);
    box-shadow: 0px 10px 5px 0px rgba(50, 50, 50, 0.56); }
    /* line 1061, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #nav-mobile ul {
      margin-bottom: 0; }
      /* line 1063, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
      #nav-mobile ul li {
        padding: 0; }
        /* line 1065, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
        #nav-mobile ul li a {
          display: block;
          width: 50px;
          height: 50px;
          margin: 0 auto;
          text-indent: -999999px;
          background-image: url(../img/sprite-mobile.png);
          background-repeat: no-repeat;
          background-position: left top;
          transition: background-color 0.5s ease; }
          /* line 1077, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
          #nav-mobile ul li a:hover, #nav-mobile ul li a.active {
            background-color: #3e6904;
            transition: background-color 0.5s ease; }
        /* line 1083, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
        #nav-mobile ul li:nth-child(2) a {
          background-position: left -50px; }
        /* line 1084, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
        #nav-mobile ul li:nth-child(3) a {
          background-position: left -100px; }
        /* line 1085, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
        #nav-mobile ul li:nth-child(4) a {
          background-position: left -150px; }
        /* line 1086, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
        #nav-mobile ul li:nth-child(5) a {
          background-position: left -200px; }
        /* line 1087, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
        #nav-mobile ul li:nth-child(6) a {
          background-position: left -250px; }
  /* line 1091, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #header {
    position: relative; }
    /* line 1093, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #header .top {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      background: none; }
      /* line 1099, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
      #header .top .bann {
        width: 100%;
        left: 0; }
        /* line 1102, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
        #header .top .bann img {
          display: block;
          max-width: 100%;
          height: auto; }
      /* line 1108, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
      #header .top .container {
        top: auto;
        bottom: 0;
        right: 0;
        left: 0;
        background: #3A88AE; }
    /* line 1116, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #header + .container {
      margin-top: 50px; }
  /* line 1120, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide > div {
    height: auto; }
  /* line 1123, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide > div .title {
    margin-top: 0px;
    padding-top: 20px; }
  /* line 1127, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide > div .offres .title {
    padding-top: 50px; }
  /* line 1133, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .page-camping > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center; }
    /* line 1144, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .page-camping > div > div {
      flex: 1 auto; } }

@media (max-width: 700px) {
  /* line 1153, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #header .top .bann {
    top: 0; }
  /* line 1156, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #header .top .container {
    position: static;
    margin-top: 280px; }
  /* line 1160, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #header + .container {
    margin-top: 210px; }
    /* line 1162, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    #header + .container .row {
      margin-bottom: 10px; }
  /* line 1167, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide {
    display: block; }
  /* line 1170, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .acces-rapide .documents {
    padding-top: 30px; }
    /* line 1173, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
    .acces-rapide .documents ul {
      position: static;
      display: block; }
  /* line 1178, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  .col-icon,
  .footer-icon .col-icon {
    width: 50%;
    -webkit-flex: none;
    flex: none; } }

/* line 1186, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
#google-t {
  position: absolute;
  right: 0;
  bottom: 0; }
  /* line 1190, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
  #google-t img {
    min-width: auto !important;
    top: auto !important;
    left: auto !important;
    right: 100%; }

/* line 1197, E:/wamp/www/fleur-des-champs/app/styles/main.scss */
.zvr-widget {
  border: 2px solid #9AD051; }
